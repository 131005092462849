import React, { Component } from "react";
import {
  Table,
  Container,
  Button,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import {
  subTotal,
  tax,
  orderTotal,
  shippingCharge,
  upadateQuantityFlag,
} from "./Table";
import { LinkContainer } from "react-router-bootstrap";
import { uploadLength } from "./Uppy";
import { Redirect } from "react-router";
import axios from "axios";
import "./Summary.css";

var leadTime;
var razorpay_order = null;
var orderId = null;
var external_access = false;
var selfpickup = false;
var OrderTotal = orderTotal;
var SubTotal = subTotal;
var Tax = tax;
var coupenTotal = 0;
var coupenApplied = true;
var coupenObj = null;
var Nfiles = 0;

class SummaryComponent extends Component {
  constructor(props) {
    super(props);
    external_access = this.props.external_access;
    this.orderId = this.props.orderId;
    orderId = this.props.orderId;

    this.state = {
      uploadLength: uploadLength,
      subTotal: subTotal,
      tax: tax,
      orderTotal: orderTotal,
      shippingCharge: shippingCharge,
      selfpickup: false,
      razorpay_order_id: null,
      modal: false,
      coupenCode: "",
      coupenLoder: false,
      coupenApplied: true,
      request: {
        name: "",
        email: "",
        phone: "",
        comment: "",
      },
      coupen: {
        code: "xyzz",
        expireDate: "2020-12-24",
        validDays: 1,
        discountAmount: 500,
        comment: "test",
        amountPercentage: 10,
        percentageFlag: true,
      },
    };
  }
  componentDidMount() {
    console.log("Summary Component Mounted");
    this.timer = setInterval(() => this.updateSummary(), 500);
  }

  async updateSummary() {
    if (subTotal < 500) {
      leadTime = "1 Day";
    } else if (subTotal < 1000) {
      leadTime = "1-2 Days";
    } else if (subTotal < 5000) {
      leadTime = "2-3 Days";
    } else if (subTotal < 10000) {
      leadTime = "2-4 Days";
    } else {
      leadTime = "Custom";
    }

    console.log("*********QFlag******", upadateQuantityFlag);

    if (Nfiles !== uploadLength || upadateQuantityFlag) {
      await this.setState({
        coupenApplied: true,
      });
    }

    if (this.state.coupenApplied) {
      OrderTotal = orderTotal;
      SubTotal = subTotal;
      Tax = tax;
    }

    if (!this.state.coupenApplied && Nfiles === uploadLength) {
      OrderTotal = coupenTotal;
      coupenApplied = this.state.coupenApplied;
    }

    this.setState({
      uploadLength: uploadLength,
      subTotal: SubTotal,
      tax: Tax,
      orderTotal: OrderTotal,
      shippingCharge: shippingCharge,
      leadTime: leadTime,
    });

    // console.log(this.state.selfpickup);
    // if (this.state.selfpickup) {
    //   let order_total = orderTotal - shippingCharge;
    //   this.setState(
    //     {
    //       shippingCharge: 0,
    //       orderTotal: order_total,
    //     },
    //     () => ((selfpickup = true), (OrderTotal = orderTotal - shippingCharge))
    //   );
    // }

    axios
      .post(`https://print.3ding.in/server/orders/orderTotal/${orderId}`, {
        orderTotal: this.state.orderTotal,
        selfpickup: this.state.selfpickup,
      })
      .then((data) => {})
      .catch(function (error) {
        console.log(error);
      });
  }

  checkoutHandler = (e) => {
    e.preventDefault();
    axios
      .post("https://print.3ding.in/server/payment", {
        orderId: orderId,
        amount: this.state.orderTotal,
        currency: "INR",
        receipt: "Receipt #20",
        payment_capture: 1,
        notes: {},
      })
      .then((data) => {
        console.log("data:", data.data.id);
        this.state.razorpay_order_id = data.data.id;
        razorpay_order = data.data.id;
        console.log(
          " this.state.razorpay_order_id",
          this.state.razorpay_order_id
        );
      })
      .catch(function (error) {
        console.log(error);
      });
    this.setState({ redirect: true });
  };

  toggle = () => {
    let value = !this.state.modal;
    this.setState({ modal: value });
  };

  onchangeHandler = (propertyName) => (event) => {
    const { request } = this.state;
    const newRequest = {
      ...request,
      [propertyName]: event.target.value,
    };
    this.setState({ request: newRequest });
  };

  selfpickupHandler = (e) => {
    let self = e.target.checked;
    var r = window.confirm("Self Pickup available in Chennai location only!");
    if (r === true) {
      this.setState({
        selfpickup: self,
      });
      this.updateSummary();
    } else {
      e.target.checked = false;
    }
  };

  requestSubmitHandler = () => {
    axios
      .post(
        `https://print.3ding.in/server/orders/requestHelp/${orderId}`,
        this.state.request
      )
      .then((data) => {
        this.toggle();
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        `https://print.3ding.in/server/mail/needHelp/${orderId}`,
        this.state.request
      )
      .then((data) => {})
      .catch(function (error) {
        console.log(error);
      });
  };

  coupenUpdate = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  coupenHandeler = async () => {
    let value = this.state.coupenCode;
    coupenObj = null;
    let newTotal;

    this.setState({ coupenLoder: true });
    let coupen = await axios.get(
      `https://print.3ding.in/server/coupen/${value}`
    );
    this.setState({ coupenLoder: false });
    if (coupen.data.data.length) {
      this.setState({ coupen: coupen.data.data[0].coupen });

      if (value && value === this.state.coupen.code) {
        if (this.state.coupenApplied) {
          let expireDate = new Date(this.state.coupen.expireDate);
          let today = new Date();
          console.log("expireDate", expireDate);
          console.log("today", today);
          if (expireDate.getTime() < today.getTime()) {
            coupenTotal = 0;
            alert("coupen expired");
            return;
          } else if (
            this.state.coupen.percentageFlag &&
            this.state.coupen.amountPercentage > 0
          ) {
            if (subTotal < 1000) {
              alert("SubTotal should be greater than Rs.1000");
              return;
            }
            let discount = Math.round(
              subTotal * (this.state.coupen.amountPercentage / 100)
            );
            if (discount > 1000) {
              discount = 1000;
            }
            SubTotal = subTotal - discount;
            Tax = Math.round((SubTotal * 18) / 100);
            newTotal = SubTotal + Tax + this.state.shippingCharge;
            coupenTotal = newTotal;
            Nfiles = uploadLength;
            await this.setState({ coupenApplied: false, coupenCode: "" });
            coupenObj = this.state.coupen;
          } else if (this.state.orderTotal > this.state.coupen.discountAmount) {
            newTotal = this.state.orderTotal - this.state.coupen.discountAmount;
            coupenTotal = newTotal;
            Nfiles = uploadLength;
            await this.setState({ coupenApplied: false, coupenCode: "" });
            coupenObj = this.state.coupen;
          } else if (
            this.state.orderTotal <= this.state.coupen.discountAmount
          ) {
            newTotal = this.state.orderTotal - this.state.coupen.discountAmount;
            coupenTotal = 0;
            Nfiles = uploadLength;

            await this.setState({ coupenApplied: false, coupenCode: "" });
            coupenObj = this.state.coupen;
            // alert(
            //   `Minimum Total should greater than Rs: ${this.state.coupen.discountAmount}`
            // );
          }
        } else {
          alert("coupen already used");
        }
      } else {
        coupenTotal = 0;
        this.setState({ coupenApplied: true });
        coupenObj = null;
      }
    } else {
      alert("coupen not valid");
      this.setState({ coupenApplied: true });
      coupenObj = null;
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/checkout" />;
    }

    const {
      discountAmount,
      amountPercentage,
      percentageFlag,
    } = this.state.coupen;

    return (
      <div style={{ width: "90%" }}>
        {external_access ? (
          <Container>
            <Row>
              <h4 style={{ marginLeft: "42%" }}>Order Summary </h4>
            </Row>
            <br /> <br />
            <Row>
              <Col sm={4} align="right">
                <Row style={{ lineHeight: 2.5 }} style={{ fontWeight: 500 }}>
                  <Col sm={4} align="left">
                    <div>Order ID: </div>
                    <div>Lead Time: </div>
                    {/* <div>Sub Total: </div>
             <div>GST 18%:   </div>
             <div>Shipping:  </div>
             <div>Total:  </div> */}
                  </Col>

                  <Col sm={5}>
                    <div style={{ textAlign: "right" }}>
                      <div>{this.orderId}</div>
                      <div>{this.state.leadTime}</div>
                    </div>

                    {/* <div>{this.state.subTotal}</div>
             <div>{this.state.tax}</div>
             <div>{this.state.shippingCharge}</div>
             <div>{this.state.orderTotal}</div> */}
                  </Col>

                  {/* <Col sm={5} ></Col> */}
                </Row>
              </Col>
              <Col sm={4} align="right"></Col>

              <Col sm={4} align="right">
                {/* <Row> */}
                <Col
                  sm={8}
                  xs={12}
                  align="center"
                  style={{ marginLeft: "40%" }}
                >
                  <Row>
                    <Col sm={6} align="right">
                      <span style={{ fontWeight: 500 }}>
                        Rs.{this.state.orderTotal}/-
                        <div style={{ fontSize: "10px" }}>
                          <div>(incl. Tax:Rs.{this.state.tax}</div>
                          <div>Shpping:Rs.{this.state.shippingCharge})</div>
                        </div>
                      </span>
                    </Col>
                    <Col sm={6}>
                      <LinkContainer to="/checkout">
                        <Button
                          variant="danger"
                          onClick={this.checkoutHandler}
                          disabled={this.state.subTotal == 0}
                        >
                          Checkout
                        </Button>
                      </LinkContainer>
                    </Col>
                  </Row>
                </Col>

                {/* </Row> */}
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <Table responsive align="center">
                <thead>
                  <tr align="center">
                    <th className="align-middle" colSpan="2" align="center">
                      Order Summary
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align-middle">Order ID</td>
                    <td className="align-middle" align="right">
                      {this.orderId}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle"># of Files</td>
                    <td className="align-middle" align="right">
                      {this.state.uploadLength}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Lead Time</td>
                    <td className="align-middle" align="right">
                      {this.state.leadTime}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Sub Total</td>
                    <td className="align-middle" align="right">
                      &#8377; {this.state.subTotal}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">GST 18%</td>
                    <td className="align-middle" align="right">
                      &#8377; {this.state.tax}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">
                      Shipping
                      {/* <div style={{ fontSize: "13px" }}>
                        {" "}
                        Self Pickup &nbsp;
                        <input
                          type="checkbox"
                          name="selfpickup"
                          onChange={this.selfpickupHandler}
                          style={{ marginTop: "6px" }}
                        />
                      </div> */}
                    </td>
                    <td className="align-middle" align="right">
                      &#8377; {this.state.shippingCharge}
                    </td>
                  </tr>

                  <tr>
                    <td className="align-middle">
                      Coupon
                      <br /> <br />
                      <Button
                        className="align-middle btn btn-success"
                        onClick={this.coupenHandeler}
                        disabled={this.state.subTotal == 0}
                      >
                        Apply&nbsp;
                        {this.state.coupenLoder ? (
                          <i
                            class="fa fa-refresh fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : null}
                      </Button>
                    </td>
                    <td className="align-middle" align="right">
                      <input
                        type="text"
                        name="coupenCode"
                        value={this.state.coupenCode}
                        onChange={this.coupenUpdate}
                        style={{ width: "100%" }}
                      />
                      {!this.state.coupenApplied ? (
                        <p style={{ fontSize: "13px" }}>
                          Coupon applied: -
                          {percentageFlag
                            ? `${amountPercentage}%`
                            : discountAmount}
                          &#x20B9;
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="align-middle">
                      <b>Total</b>
                    </td>
                    <td className="align-middle" align="right">
                      &#8377; {this.state.orderTotal}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={6} align="center">
                <span style={{ fontSize: "13px" }} className="aa">
                  <Button
                    variant="outline-success"
                    style={{ width: "118%" }}
                    onClick={this.toggle}
                    disabled={this.state.subTotal === 0}
                  >
                    <span
                      style={{ fontSize: "13px", width: "118%" }}
                      className="aa"
                    >
                      Manual Quote
                    </span>{" "}
                  </Button>
                  <span
                    className={
                      this.state.subTotal > 0 ? "hide-tooptipp" : "tooltiptextt"
                    }
                  >
                    {" "}
                    Please upload a STL file <br /> to request help
                  </span>{" "}
                </span>
              </Col>

              <Col sm={6} align="center">
                <LinkContainer to="/checkout">
                  <Button
                    variant="success"
                    onClick={this.checkoutHandler}
                    disabled={this.state.subTotal == 0}
                  >
                    <span style={{ fontSize: "13px" }}> Checkout</span>
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
          </Container>
        )}

        <Modal show={this.state.modal} onHide={this.toggle}>
          <Modal.Header closeButton>
            <div style={{ marginLeft: "22%" }}>
              <h5>Request Manual Assistance</h5>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div align="center">
              <div>
                <h6>
                  An Engineer would review your files for printability & if
                  applicable, will adjust the pricing
                </h6>
              </div>

              <br />
              <Form.Group>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  defaultValue={this.state.request.name}
                  onChange={this.onchangeHandler("name")}
                />
              </Form.Group>

              <Form.Group>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  defaultValue={this.state.request.email}
                  onChange={this.onchangeHandler("email")}
                />
                <p
                  className={
                    this.state.request.email === ""
                      ? "validation_error"
                      : "validation_true"
                  }
                >
                  Email adddess required
                </p>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="Enter Phone"
                  defaultValue={this.state.request.phone}
                  onChange={this.onchangeHandler("phone")}
                />
              </Form.Group>

              <Form.Group>
                <Form.Control
                  as="textarea"
                  name="comment"
                  placeholder="Enter Comment"
                  rows="3"
                  defaultValue={this.state.request.comment}
                  onChange={this.onchangeHandler("comment")}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={this.requestSubmitHandler}
              style={{ float: "left" }}
              disabled={this.state.request.email === ""}
            >
              Submit
            </Button>{" "}
            {/* <Button variant="success" onClick={this.toggle}>Cancel</Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default SummaryComponent;
export {
  razorpay_order,
  orderId,
  selfpickup,
  OrderTotal,
  coupenApplied,
  coupenObj,
};
