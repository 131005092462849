import React, { Component } from "react";
import { uploadLength } from "./Uppy";
// import { BrowserRouter as Router, Link, Route } from "react-router-dom";
// import Checkout from "./Checkout";

import { Table, Row, Col, Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import "./table.css";
import axios from "axios";
// import "./status.css";
// import Center from "react-center";

var processOptions = [];
var materialOptions = [];
var colorOptions = [];
var qualityOptions = [];
var densityOptions = [];

var filesArray = [];
var subTotal = 0;
var tax = 0;
var orderTotal = 0;
var shippingCharge = 0;
var fileArrayLenth = 0;

var orderSummary = [];
var external_access = false;
var orderId = null;
var upadateQuantityFlag = false;

var Process = {
  value: "fdm",
  label: "FDM/FFF",
};
var Material = {
  value: "fdm-pla",
  label: "PLA",
};
var Color = {
  value: "fdm-pla-white",
  label: "White",
};
var Quality = {
  value: "fdm-300-microns",
  label: "Draft 300 Microns",
};
var Density = {
  value: "fdm-density-20",
  label: "20%",
};
var Quantity = {
  value: 1,
  label: "1",
};

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: 13,
    // width:"110px"
    // overflow: "visible"
  }),
};
export default class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.orderId = this.props.orderId;
    orderId = this.props.orderId;
    external_access = this.props.external_access;
    // this.orderId = "orderid_1554193108323";

    this.getProcessOptions();
    this.getMaterialOptions("");
    this.getColorOptions("");
    this.getQualityOptions("");
    this.getDensityOptions("");

    this.state = {
      refresh: true,
      modal: true,
      uploadLength: uploadLength,
      filesArray: [],
      request: {
        name: "",
        email: "",
        phone: "",
        comment: "",
      },

      defaultProcess: {
        value: "fdm",
        label: "FDM/FFF",
      },
      defaultMaterial: {
        value: "fdm-pla",
        label: "PLA",
      },
      defaultColor: {
        value: "fdm-pla-white",
        label: "White",
      },
      defaultQuality: {
        value: "fdm-300-microns",
        label: "Draft 300 Microns",
      },
      defaultDensity: {
        value: "fdm-density-20",
        label: "20%",
      },
      defaultQuantity: {
        value: 1,
        label: "1",
      },
    };
  }

  componentDidMount() {
    console.log("Table Component Mounted");
    console.log("this.external_access", this.external_access);
    // if(this.external_access !== true){
    //   this.timer = setInterval(() => this.checkForUploads(), 500);
    // }

    this.timer = setInterval(() => this.checkForUploads(), 500);

    this.getFiles();
  }

  checkForUploads() {
    if (external_access !== true) {
      if (filesArray.length !== uploadLength) {
        console.log("Getting Files");
        this.getFiles();
        console.log(
          "Order Length after Getting Files",
          filesArray.length,
          uploadLength
        );
      } else {
        // console.log(
        //   "No Change in Order Data Length",
        //   filesArray.length,
        //   uploadLength
        // );
      }
    }
  }

  //Write to DB
  updateOrder() {
    // var index = filesArray.findIndex(file => file._id === fileId);
    console.log("Writing Order to DB");
    filesArray.forEach((file) => {
      // console.log("File", file.index, JSON.stringify(file));
      fetch("https://print.3ding.in/server/files/" + file._id, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ a: 1, b: "Textual content" })
        body: JSON.stringify(file),

        // headers: new Headers(),
        // body: JSON.stringify({
        //   name: "Suren",
        //   age: "29"
        // })
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log(resData);
        });
    });
    // filesArray[index].item = value;
    // // console.log("File ID:", fileId);
    // console.log("File Data:", filesArray[index]);
    // fetch("https://print.3ding.in/server/files/" + fileId, {
    //   method: "PATCH",
    //   headers: new Headers()
    //   // body: JSON.stringify({ item, value })
    // })
    //   .then(response => response.json())
    //   .then(resData => {
    //     console.log("Patch Result:", resData);
    //   });
    // // console.log("File ID:", fileId);
    // // console.log("Order Data:", this.state.filesArray);
  }

  getFiles() {
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    const url = "https://print.3ding.in/server/files/"; // site that doesn’t send Access-Control-*
    fetch(url + this.orderId)
      .then((response) => response.json())
      .then((resData) => {
        filesArray = resData;
        this.setState({ filesArray: resData });
        // console.log("Order Data:", this.state.filesArray);
        // console.log("Global Order Data:", filesArray);
        setTimeout(() => {
          this.updateDefaultOptions();
          this.calculatePrice();
        }, 1000);
      });
  }

  // Update Initial Options
  updateDefaultOptions() {
    console.log("Updating Default Options");
    // console.log("Default Process Option:", processOptions[0].value);
    // console.log("Default Material Option:", materialOptions[0].value);
    // filesArray.map(file => {
    filesArray.forEach((file) => {
      // Default Process
      if (
        file.process === undefined ||
        this.state.defaultProcess.value !== "fdm"
      ) {
        file.process = this.state.defaultProcess.value;
      }

      file.processLabel = processOptions.find(
        (index) => index.value === file.process
      ).label;

      console.log("Default Process", file.process, file.processLabel);

      // Default Material
      if (file.material === undefined) {
        file.material = this.state.defaultMaterial.value;
      }
      file.materialLabel = materialOptions.find(
        (index) => index.value === file.material
      ).label;
      file.materialPrice = materialOptions.find(
        (index) => index.value === file.material
      ).materialPrice;

      // if(file.material !== undefined){
      //   this.state.defaultMaterial.value=file.material;
      //   this.state.defaultMaterial.label=file.materialLabel;
      // }

      // console.log(
      //   "Default Material",
      //   file.material,
      //   file.materialLabel,
      //   file.materialPrice
      // );

      // Default Color
      if (file.color === undefined) {
        file.color = this.state.defaultColor.value;
      }
      file.colorLabel = colorOptions.find(
        (index) => index.value === file.color
      ).label;

      // console.log("Default Color", file.color, file.colorLabel);

      // Default Quality
      if (file.quality === undefined) {
        file.quality = this.state.defaultQuality.value;
      }
      file.qualityLabel = qualityOptions.find(
        (index) => index.value === file.quality
      ).label;
      file.qualityMultiplier = qualityOptions.find(
        (index) => index.value === file.quality
      ).qualityMultiplier;

      // console.log(
      //   "Default Quality",
      //   file.quality,
      //   file.qualityLabel,
      //   file.qualityMultiplier
      // );

      // Default Density
      if (file.density === undefined) {
        file.density = this.state.defaultDensity.value;
      }
      file.densityLabel = densityOptions.find(
        (index) => index.value === file.density
      ).label;
      file.densityMultiplier = densityOptions.find(
        (index) => index.value === file.density
      ).densityMultiplier;

      if (file.quantity === undefined) {
        file.quantity = 1;
      }

      // console.log(
      //   "Default Density",
      //   file.density,
      //   file.densityLabel,
      //   file.densityMultiplier
      // );

      // this.setState({
      //   defaultProcess.
      //     label: file.processLabel
      //  defaultMaterialLabel: file.materialLabel
      // });
    });
    // console.log(this.state.defaultProcess);
    console.log("Order Data", filesArray);
    // this.calculatePrice();
  }

  // Get Process Options

  process = null;
  getProcessOptions() {
    const url = "https://print.3ding.in/server/process/"; // site that doesn’t send Access-Control-*
    console.log("before fetch starting");
    fetch(url)
      .then((response) => response.json())
      .then((resData) => {
        processOptions = resData.map((key) => ({
          value: key.process,
          label: key.processLabel,
        }));
        console.log("Global Process Options:", processOptions);
        this.process = processOptions;
      });
  }

  // Get Material Options
  getMaterialOptions(process) {
    fetch("https://print.3ding.in/server/materials/" + process)
      .then((response) => response.json())
      .then((resData) => {
        materialOptions = resData.map((key) => ({
          value: key.material,
          label: key.materialLabel,
          process: key.process,
          materialPrice: key.materialPrice,
        }));

        // this.setState({
        //   materialOptions: resData.map(key => ({
        //     value: key.materialLabel,
        //     label: key.materialName
        //   }))
        // });
        // console.log("Material Options:", this.state.materialOptions);
        console.log("Global Material Options:", materialOptions);
      });
  }

  // Get Color Options
  getColorOptions(material) {
    fetch("https://print.3ding.in/server/colors/" + material)
      .then((response) => response.json())
      .then((resData) => {
        colorOptions = resData.map((key) => ({
          value: key.color,
          label: key.colorLabel,
          material: key.material,
        }));

        // this.setState({
        //   colorOptions: resData.map(key => ({
        //     value: key.colorLabel,
        //     label: key.colorName
        //   }))
        // });
        // console.log("Color Options:", this.state.colorOptions);
        console.log("Global Color Options:", colorOptions);
      });
  }

  // Get Quality Options
  getQualityOptions(process) {
    fetch("https://print.3ding.in/server/quality/" + process)
      .then((response) => response.json())
      .then((resData) => {
        qualityOptions = resData.map((key) => ({
          value: key.quality,
          label: key.qualityLabel,
          process: key.process,
          qualityMultiplier: key.qualityMultiplier,
        }));
        // this.setState({
        //   qualityOptions: resData.map(key => ({
        //     value: key.qualityLabel,
        //     label: key.qualityName
        //   }))
        // });
        // console.log("Quality Options:", this.state.qualityOptions);
        console.log("Global Quality Options:", qualityOptions);
      });
  }

  // Get Density Options
  getDensityOptions(process) {
    fetch("https://print.3ding.in/server/density/" + process)
      .then((response) => response.json())
      .then((resData) => {
        densityOptions = resData.map((key) => ({
          value: key.density,
          label: key.densityLabel,
          process: key.process,
          densityMultiplier: key.densityMultiplier,
        }));

        // this.setState({
        //   densityOptions: resData.map(key => ({
        //     value: key.densityLabel,
        //     label: key.densityName
        //   }))
        // });
        // console.log("Density Options:", this.state.densityOptions);
        console.log("Global Density Options:", densityOptions);
      });
  }

  // Calculate Price
  calculatePrice() {
    console.log("Calculating Price");

    subTotal = 0;

    filesArray.forEach((file) => {
      // console.log(file.material);
      // console.log(qualityOptions);

      file.materialPrice = materialOptions.find(
        (index) => index.value === file.material
      ).materialPrice;

      file.qualityMultiplier = qualityOptions.find(
        (index) => index.value === file.quality
      ).qualityMultiplier;

      file.densityMultiplier = densityOptions.find(
        (index) => index.value === file.density
      ).densityMultiplier;

      if (file.process === "sls") {
        if (Math.round(file.volume) <= 25) {
          file.price = 1300;
        } else if (
          Math.round(file.volume) >= 25 &&
          Math.round(file.volume) <= 100
        ) {
          file.price = Math.round(file.volume) * 52;
        } else {
          file.price = Math.round(file.volume) * 45;
        }
      } else if (file.process === "sla" && Math.round(file.volume) <= 10) {
        if (Math.round(file.volume) <= 10) {
          file.price = 1500;
        }
      } else if (
        (file.process === "mjf" ||
          file.process === "dlp" ||
          file.process === "mjp" ||
          file.process === "pjp") &&
        Math.round(file.volume) <= 10
      ) {
        if (Math.round(file.volume) <= 10) {
          file.price = 2000;
        }
      } else if (
        file.process === "fdm" &&
        (file.dimensions[0] >= 300 ||
          file.dimensions[1] >= 300 ||
          file.dimensions[2] >= 300)
      ) {
        file.price =
          Math.round(
            file.volume *
              file.materialPrice *
              file.qualityMultiplier *
              file.densityMultiplier
          ) * 2;
      } else {
        file.price = Math.round(
          file.volume *
            file.materialPrice *
            file.qualityMultiplier *
            file.densityMultiplier
        );
      }

      if (
        file.custom_flag === "" ||
        file.custom_flag === false ||
        file.custom_flag === null ||
        file.custom_flag === undefined ||
        file.custom_flag === NaN
      ) {
        file.itemTotal = file.price * file.quantity;
        if (file.itemTotal > 0 && file.itemTotal < 150) {
          file.price = 150;
          file.itemTotal = 150;
        }
      }
      if (file.custom_flag === true) {
        file.itemTotal = file.custom_amount * file.quantity;
      }

      // file.itemTotal = file.price * file.quantity;
      // if((file.itemTotal > 0) && (file.itemTotal < 100)){
      //   file.price=100;
      //   file.itemTotal = 100
      // }
      subTotal += file.itemTotal;
      shippingCharge = 0;
      console.log(
        file.volume,
        file.materialPrice,
        file.qualityMultiplier,
        file.densityMultiplier,
        file.quantity,
        file.price,
        file.itemTotal
      );
    });

    tax = Math.round((subTotal * 18) / 100);

    if (subTotal < 1000 && subTotal > 0) {
      shippingCharge = 150;
      orderTotal = subTotal + tax + shippingCharge;
    } else {
      orderTotal = subTotal + tax;
    }

    console.log("Sub Total", subTotal);
    console.log("Tax", tax);
    console.log("Total", orderTotal);
    console.log("Order Data", filesArray);

    // this.setState({
    //   orderTotal: orderTotal
    // });
    // orderTotal = this.state.orderTotal;

    this.setState({
      state: this.state,
    });
    this.updateOrder();
  }

  reloadDropdown = () => {
    console.log("raload called");
    this.setState({
      refresh: false,
    });
    this.setState({
      refresh: true,
    });
  };

  toggle = () => {
    let value = !this.state.modal;
    this.setState({ modal: value });
  };

  onchangeHandler = (propertyName) => (event) => {
    const { request } = this.state;
    const newRequest = {
      ...request,
      [propertyName]: event.target.value,
    };
    this.setState({ request: newRequest });
  };

  requestSubmitHandler = () => {
    console.log("reqsub");
    axios
      .post(
        `https://print.3ding.in/server/orders/requestHelp/${orderId}`,
        this.state.request
      )
      .then((data) => {
        this.toggle();
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        `https://print.3ding.in/server/mail/needHelp/${orderId}`,
        this.state.request
      )
      .then((data) => {})
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    // var liClasses = classNames({
    //   'align-middle': true,
    //   'tooltip': true
    // });
    console.log("process:", this.process);

    console.log("file array after render:", this.state.filesArray);

    return (
      <div style={{ width: "100%" }}>
        {external_access ? (
          <div align="center">
            <p style={{ width: "70%", display: "none" }}>Order summay</p>
          </div>
        ) : (
          <div align="center">
            <hr />
            <p align="center" style={{ width: "70%" }}>
              Export CAD designs in STL format, upload using the box above,
              select printing options from the table below to see instant
              prices, checkout & pay to confirm your order. 
            </p>
            <hr />
          </div>
        )}

        {/* <Col sm={3} xs={12}>
          <Row>
            <Col sm={12} align="right">
              <Button
                variant="outline-success"
                onClick={() => {
                  console.log("Sub Total", subTotal);
                  console.log("Tax", tax);
                  console.log("Order Total", orderTotal);
                }}
              >
                Place Order
              </Button>
            </Col>
          </Row>
        </Col> */}
        {/*  */}
        {/*  */}
        {/* Table */}
        {/*  */}
        {/*  */}
        <Row>
          <Col sm={12} xs={12}>
            <Table align="center" responsive>
              <thead>
                <tr align="center">
                  <th className="align-middle">#</th>
                  <th className="align-middle" align="left" width="20%">
                    File Name
                  </th>
                  <th className="align-middle " align="center">
                    <div className="a1">
                      Technology<sup style={{ color: "black" }}> ?</sup>{" "}
                      <span className="tooltiptext">
                        <b style={{ fontWeight: "bold" }}>FFF/FDM</b>: The most
                        affordable type of 3D Printing. Perfect for quick
                        prototypes.
                        <br />
                        <br />
                        More to be added soon...
                      </span>{" "}
                    </div>
                  </th>
                  <th className="align-middle" align="center">
                    <div className="a1">
                      Material<sup style={{ color: "black" }}>?</sup>
                      <span className="tooltiptext">
                        {" "}
                        <b style={{ fontWeight: "bold" }}>PLA</b>: The most
                        affordable material in FDM.
                        <br />
                        <br />
                        <b style={{ fontWeight: "bold" }}>ABS</b>: Choice of
                        most Plastic Manufacturing industries. Can be grinded
                        and sanded post 3D Printing.
                        <br />
                        <br />
                        <b style={{ fontWeight: "bold" }}>HIPS</b>: An
                        Industrial Plastic that’s stronger & harder than PLA &
                        ABS. Less prone to deformity.
                        <br />
                        <br />
                        More to be added soon...
                      </span>
                    </div>
                  </th>
                  <th className="align-middle" align="center">
                    Color
                  </th>
                  <th className="align-middle" align="center">
                    <div className="a1">
                      {" "}
                      Quality<sup style={{ color: "black" }}>?</sup>
                      <span className="tooltiptext">
                        <b style={{ paddingLeft: "28%" }}>Layer Height</b>{" "}
                        <br />
                        <br />
                        Draft: 0.3 mm. Fast, Affordable & perfect for quick
                        trials. Good for starters.
                        <br />
                        <br />
                        Standard: 0.2 mm. Most chosen.
                        <br />
                        <br />
                        High: 0.1mm. High res; Gives a smoother surface quality.
                        Consumes time & hence Expensive.
                        <br />
                        <br />
                      </span>
                    </div>
                  </th>
                  <th className="align-middle" align="center">
                    <div className="a1">
                      {" "}
                      Density<sup style={{ color: "black" }}>?</sup>
                      <span className="tooltiptext">
                        {" "}
                        <b style={{ paddingLeft: "36%" }}>Density</b>
                        <br />
                        <br />
                        The infill percentage inside a print - Contributes to
                        the strength. 20% is the default.
                      </span>
                    </div>
                  </th>
                  <th className="align-middle" align="center">
                    Price
                  </th>
                  <th className="align-middle" align="center" width="5%">
                    Quantity
                  </th>
                  <th className="align-middle" align="center" width="10%">
                    Item Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.filesArray.map(
                  (file) => (
                    // (file.process !== undefined) ?
                    // Process= {
                    //   value: file.process,
                    //   label: file.processLabel
                    // }:
                    (process = this.state.defaultProcess),
                    file.material !== undefined
                      ? (Material = {
                          value: file.material,
                          label: file.materialLabel,
                        })
                      : (Material = this.state.defaultMaterial),
                    file.color !== undefined
                      ? (Color = {
                          value: file.color,
                          label: file.colorLabel,
                        })
                      : (Color = this.state.defaultColor),
                    file.quality !== undefined
                      ? (Quality = {
                          value: file.quality,
                          label: file.qualityLabel,
                        })
                      : (Quality = this.state.defaultQuality),
                    file.density !== undefined
                      ? (Density = {
                          value: file.density,
                          label: file.densityLabel,
                        })
                      : (Density = this.state.defaultDensity),
                    file.quantity !== undefined
                      ? (Quantity = {
                          value: file.quantity,
                          label: file.quantity,
                        })
                      : (Quantity = this.state.defaultQuantity),
                    (
                      <tr key={file._id}>
                        {/* Serial Number */}
                        <td className="align-middle">
                          {
                            (file.index =
                              filesArray.findIndex(
                                (array) => array._id === file._id
                              ) + 1)
                          }
                        </td>

                        {/*  */}
                        {/*  */}
                        {/* File Name */}
                        {/*  */}
                        {/*  */}

                        <td className="align-middle" align="left">
                          {file.fileName}
                          <div style={{ fontSize: 12 }}>
                            {file.price === 0 ? (
                              <span className="a1">
                                {Math.round(file.dimensions[0])}*
                                {Math.round(file.dimensions[1])}*
                                {Math.round(file.dimensions[2])} mm{" "}
                                <span style={{ color: "red" }}>
                                  <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="tooltiptext">
                                  This file seems too small, Kindly check the
                                  dimensions. <br />
                                  If it was designed in inches, kindly export in
                                  mm & upload again.
                                </span>
                                {/* {Math.round(file.volume)} cc  */}
                              </span>
                            ) : (
                              <span>
                                {Math.round(file.dimensions[0])}*
                                {Math.round(file.dimensions[1])}*
                                {Math.round(file.dimensions[2])} mm
                                {/* {Math.round(file.volume)} cc */}
                              </span>
                            )}
                          </div>
                        </td>

                        {/*  */}
                        {/*  */}
                        {/*  */}
                        {/* Process Dropdown */}
                        {/*  */}
                        {/*  */}
                        {/*  */}

                        <td className="align-middle" align="center">
                          {console.log(
                            "file.materialLabel from material",
                            file.materialLabel
                          )}
                          <Select
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                            })}
                            styles={customStyles}
                            style={{ fontSize: "12px" }}
                            // defaultValue={this.state.defaultProcess}
                            defaultValue={Process}
                            isDisabled={file.custom_flag === true}
                            options={processOptions}
                            onChange={(value) => {
                              console.log("Process Changed", value.label);
                              var index = filesArray.findIndex(
                                (array) => array._id === file._id
                              );
                              console.log("Index", index);
                              filesArray[index].process = value.value;
                              filesArray[index].processLabel = value.label;
                              console.log("File Data", filesArray[index]);

                              console.log(
                                "onFocus Material Options",
                                file.process,
                                materialOptions.filter(
                                  (material) =>
                                    material.process === file.process
                                )
                              );
                              this.setState({
                                materialOptions: materialOptions.filter(
                                  (material) =>
                                    material.process === file.process
                                ),
                                colorOptions: colorOptions.filter(
                                  (color) => color.material === file.material
                                ),
                                qualityOptions: qualityOptions.filter(
                                  (quality) => quality.process === file.process
                                ),
                                densityOptions: densityOptions.filter(
                                  (density) => density.process === file.process
                                ),
                              });

                              filesArray[
                                index
                              ].material = materialOptions.filter(
                                (material) => material.process === file.process
                              )[0].value;
                              filesArray[
                                index
                              ].materialLabel = materialOptions.filter(
                                (material) => material.process === file.process
                              )[0].label;
                              filesArray[
                                index
                              ].materialPrice = materialOptions.filter(
                                (material) => material.process === file.process
                              )[0].materialPrice;

                              filesArray[index].color = colorOptions.filter(
                                (color) => color.material === file.material
                              )[0].value;
                              filesArray[
                                index
                              ].colorLabel = colorOptions.filter(
                                (color) => color.material === file.material
                              )[0].label;

                              filesArray[index].quality = qualityOptions.filter(
                                (quality) => quality.process === file.process
                              )[0].value;
                              filesArray[
                                index
                              ].qualityLabel = qualityOptions.filter(
                                (quality) => quality.process === file.process
                              )[0].label;
                              filesArray[
                                index
                              ].qualityMultiplier = qualityOptions.filter(
                                (quality) => quality.process === file.process
                              )[0].qualityMultiplier;

                              filesArray[index].density = densityOptions.filter(
                                (density) => density.process === file.process
                              )[0].value;
                              filesArray[
                                index
                              ].densityLabel = densityOptions.filter(
                                (density) => density.process === file.process
                              )[0].label;
                              filesArray[
                                index
                              ].densityMultiplier = densityOptions.filter(
                                (density) => density.process === file.process
                              )[0].densityMultiplier;

                              this.calculatePrice();
                            }}
                          />
                        </td>
                        {console.log("Material:", Material)}

                        {/*  */}
                        {/*  */}
                        {/*  */}
                        {/* Material Dropdown */}
                        {/*  */}
                        {/*  */}
                        {/*  */}

                        <td className="align-middle" align="center">
                          <Select
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                            })}
                            styles={customStyles}
                            style={{ fontSize: "12px" }}
                            // defaultValue={this.state.defaultMaterial}

                            value={Material}
                            isDisabled={file.custom_flag === true}
                            selected
                            onFocus={() => {
                              console.log(
                                "onFocus Material Options",
                                file.process,
                                materialOptions.filter(
                                  (material) =>
                                    material.process === file.process
                                )
                              );
                              this.setState({
                                materialOptions: materialOptions.filter(
                                  (material) =>
                                    material.process === file.process
                                ),
                              });
                            }}
                            options={this.state.materialOptions}
                            onChange={(value) => {
                              //  this.reloadDropdown();
                              console.log("Material Changed", value.label);
                              var index = filesArray.findIndex(
                                (array) => array._id === file._id
                              );
                              console.log("Index", index);

                              filesArray[index].material = value.value;
                              filesArray[index].materialLabel = value.label;
                              filesArray[index].materialPrice =
                                value.materialPrice;

                              console.log(
                                "onFocus Color Options",
                                file.material,
                                colorOptions.filter(
                                  (color) => color.material === file.material
                                )
                              );
                              this.setState({
                                colorOptions: colorOptions.filter(
                                  (color) => color.material === file.material
                                ),
                              });

                              filesArray[index].color = colorOptions.filter(
                                (color) => color.material === file.material
                              )[0].value;
                              filesArray[
                                index
                              ].colorLabel = colorOptions.filter(
                                (color) => color.material === file.material
                              )[0].label;

                              console.log("File Data", filesArray[index]);
                              this.calculatePrice();
                              // this.reloadDropdown();
                            }}
                          />
                        </td>

                        {/*  */}
                        {/*  */}
                        {/*  */}
                        {/* Color Dropdown */}
                        {/*  */}
                        {/*  */}
                        {/*  */}

                        <td className="align-middle" align="center">
                          <Select
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                            })}
                            styles={customStyles}
                            style={{ fontSize: "12px" }}
                            // defaultValue={this.state.defaultColor}
                            // defaultValue={Color}
                            value={Color}
                            isDisabled={file.custom_flag === true}
                            selected
                            onFocus={() => {
                              console.log(
                                "onFocus Color Options",
                                file.material,
                                colorOptions.filter(
                                  (color) => color.material === file.material
                                )
                              );
                              this.setState({
                                colorOptions: colorOptions.filter(
                                  (color) => color.material === file.material
                                ),
                              });
                            }}
                            options={this.state.colorOptions}
                            onChange={(value) => {
                              console.log("Color Changed", value.label);
                              var index = filesArray.findIndex(
                                (array) => array._id === file._id
                              );
                              console.log("Index", index);

                              filesArray[index].color = value.value;
                              filesArray[index].colorLabel = value.label;

                              console.log("File Data", filesArray[index]);
                              this.calculatePrice();
                            }}
                          />
                        </td>

                        {/*  */}
                        {/*  */}
                        {/*  */}
                        {/* Quality Dropdown */}
                        {/*  */}
                        {/*  */}
                        {/*  */}

                        <td className="align-middle" align="center">
                          <Select
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                            })}
                            styles={customStyles}
                            style={{ fontSize: "12px" }}
                            // defaultValue={this.state.defaultQuality}
                            defaultValue={Quality}
                            value={Quality}
                            isDisabled={file.custom_flag === true}
                            selected
                            onFocus={() => {
                              console.log(
                                "onFocus Quality Options",
                                file.process,
                                qualityOptions.filter(
                                  (quality) => quality.process === file.process
                                )
                              );
                              this.setState({
                                qualityOptions: qualityOptions.filter(
                                  (quality) => quality.process === file.process
                                ),
                              });
                            }}
                            options={this.state.qualityOptions}
                            onChange={(value) => {
                              console.log("Quality Changed", value.label);
                              var index = filesArray.findIndex(
                                (array) => array._id === file._id
                              );
                              console.log("Index", index);
                              console.log(
                                "Quality Multiplier",
                                value.qualityMultiplier
                              );

                              filesArray[index].quality = value.value;
                              filesArray[index].qualityLabel = value.label;
                              filesArray[index].qualityMultiplier =
                                value.qualityMultiplier;

                              console.log("File Data", filesArray[index]);
                              this.calculatePrice();
                            }}
                          />
                        </td>

                        {/*  */}
                        {/*  */}
                        {/*  */}
                        {/* Density Dropdown */}
                        {/*  */}
                        {/*  */}
                        {/*  */}

                        <td className="align-middle" align="center">
                          <Select
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                            })}
                            styles={customStyles}
                            style={{ fontSize: "12px" }}
                            // defaultValue={this.state.defaultDensity}
                            // defaultValue={Density}
                            value={Density}
                            isDisabled={file.custom_flag === true}
                            selected
                            onFocus={() => {
                              console.log(
                                "onFocus Density Options",
                                file.process,
                                densityOptions.filter(
                                  (density) => density.process === file.process
                                )
                              );
                              this.setState({
                                densityOptions: densityOptions.filter(
                                  (density) => density.process === file.process
                                ),
                              });
                            }}
                            options={this.state.densityOptions}
                            onChange={(value) => {
                              console.log("Density Changed", value.label);
                              var index = filesArray.findIndex(
                                (array) => array._id === file._id
                              );
                              console.log("Index", index);

                              filesArray[index].density = value.value;
                              filesArray[index].densityLabel = value.label;
                              filesArray[index].densityMultiplier =
                                value.densityMultiplier;

                              console.log("File Data", filesArray[index]);
                              this.calculatePrice();
                            }}
                          />
                        </td>

                        {file.custom_flag === true ? (
                          <td className="align-middle" align="right">
                            &#8377; {file.custom_amount}
                          </td>
                        ) : (
                          <td className="align-middle" align="right">
                            &#8377; {file.price}
                          </td>
                        )}

                        {/*  */}
                        {/*  */}
                        {/*  */}
                        {/* Quantity Dropdown */}
                        {/*  */}
                        {/*  */}
                        {/*  */}

                        <td className="align-middle" align="center">
                          <Select
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                            })}
                            styles={customStyles}
                            style={{ fontSize: "12px" }}
                            // defaultValue={{
                            //   value: 1,
                            //   label: "1"
                            // }}

                            defaultValue={Quantity}
                            options={[
                              {
                                value: 0,
                                label: 0,
                              },
                              {
                                value: 1,
                                label: 1,
                              },
                              {
                                value: 2,
                                label: 2,
                              },
                              {
                                value: 3,
                                label: 3,
                              },
                              {
                                value: 4,
                                label: 4,
                              },
                              {
                                value: 5,
                                label: 5,
                              },
                              {
                                value: 6,
                                label: 6,
                              },
                              {
                                value: 7,
                                label: 7,
                              },
                              {
                                value: 8,
                                label: 8,
                              },
                              {
                                value: 9,
                                label: 9,
                              },
                              {
                                value: 10,
                                label: 10,
                              },
                              {
                                value: 20,
                                label: 20,
                              },
                              {
                                value: 50,
                                label: 50,
                              },
                              {
                                value: 100,
                                label: 100,
                              },
                            ]}
                            onChange={(value) => {
                              console.log("Quantity Changed", value.label);
                              var index = filesArray.findIndex(
                                (array) => array._id === file._id
                              );
                              console.log("Index", index);
                              filesArray[index].quantity = value.value;
                              console.log("File Data", filesArray[index]);
                              upadateQuantityFlag = true;
                              setTimeout(() => {
                                upadateQuantityFlag = false;
                              }, 1000);
                              this.calculatePrice();
                            }}
                          />
                        </td>
                        <td className="align-middle" align="right">
                          &#8377; {file.itemTotal}
                        </td>

                        {file.price > 4000 && external_access !== true ? (
                          <Modal show={this.state.modal} onHide={this.toggle}>
                            <Modal.Header closeButton>
                              <div style={{ marginLeft: "22%" }}>
                                <h5>Request Manual Assistance</h5>
                              </div>
                            </Modal.Header>
                            <Modal.Body>
                              <div>
                                <div style={{ textAlign: "justify" }}>
                                  <h6>
                                    If you feel the price is incorrectly too
                                    high, select your preferred options here &
                                    drop a message using the manual quote button
                                    above. An Engineer will analyse & if
                                    applicable, will adjust the prices
                                    accordingly. You'll receive a link via mail
                                    to continue from where you left.
                                  </h6>
                                </div>

                                <br />
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter Name"
                                    defaultValue={this.state.request.name}
                                    onChange={this.onchangeHandler("name")}
                                  />
                                </Form.Group>

                                <Form.Group>
                                  <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    defaultValue={this.state.request.email}
                                    onChange={this.onchangeHandler("email")}
                                  />
                                </Form.Group>

                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    name="phone"
                                    placeholder="Enter Phone"
                                    defaultValue={this.state.request.phone}
                                    onChange={this.onchangeHandler("phone")}
                                  />
                                </Form.Group>

                                <Form.Group>
                                  <Form.Control
                                    as="textarea"
                                    name="comment"
                                    placeholder="Enter Comment"
                                    rows="3"
                                    defaultValue={this.state.request.comment}
                                    onChange={this.onchangeHandler("comment")}
                                  />
                                </Form.Group>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="success"
                                onClick={this.requestSubmitHandler}
                                style={{ float: "left" }}
                              >
                                Submit
                              </Button>{" "}
                            </Modal.Footer>
                          </Modal>
                        ) : null}
                      </tr>
                    )
                  )
                )}

                {/* {
                 external_access ? <div></div> :
                 <div><br/><br/><br/><br/><br/><br/><br/><br/></div> 
             } */}

                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </tbody>
            </Table>
          </Col>
        </Row>

        {/* <Modal show={this.state.modal} onHide={this.toggle}  >
          <Modal.Header closeButton>
            <div style={{marginLeft:"22%"}}>
              <h5>Request Manual Assistance</h5>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div style={{textAlign:"justify"}}>
              <h6 >
              If you feel the price is incorrectly too high, select your preferred options here & drop a message using the manual quote button above. 
              An Engineer will analyse & if applicable, will adjust the prices accordingly. You'll receive a link via mail to continue from where you left.
             </h6>
              </div>
            

              <br />
              <Form.Group >

                <Form.Control type="text" name="name" placeholder="Enter Name" defaultValue={this.state.request.name} onChange={this.onchangeHandler("name")} />
              </Form.Group>

              <Form.Group>

                <Form.Control type="email" name="email" placeholder="Enter email"defaultValue={this.state.request.email} onChange={this.onchangeHandler("email")} />
              </Form.Group>

              <Form.Group>

                <Form.Control type="text" name="phone" placeholder="Enter Phone" defaultValue={this.state.request.phone} onChange={this.onchangeHandler("phone")} />
              </Form.Group>

              <Form.Group  >

                <Form.Control as="textarea" name="comment" placeholder="Enter Comment" rows="3" defaultValue={this.state.request.comment} onChange={this.onchangeHandler("comment")} />
              </Form.Group>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.requestSubmitHandler} style={{float:"left"}}>Submit</Button>{' '}
             
          </Modal.Footer>
        </Modal> */}

        {/* <Row>
          <Col sm={3} xs={12}>
            <Row>
              <Table responsive align="center">
                <thead>
                  <tr align="center">
                    <th className="align-middle" colSpan="2" align="center">
                      Order Summary
                      <Button
                        variant="outline-success"
                        onClick={() => {
                          console.log("Refreshing..");
                          this.getFiles();
                        }}
                      >
                        Refresh
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align-middle">Order ID</td>
                    <td className="align-middle" align="right">
                      {this.orderId}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Files</td>
                    <td className="align-middle" align="right">
                      {this.state.filesArray.length}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Sub Total</td>
                    <td className="align-middle" align="right">
                      &#8377; {subTotal}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">GST 18%</td>
                    <td className="align-middle" align="right">
                      &#8377; {tax}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Total</td>
                    <td className="align-middle" align="right">
                      &#8377; {orderTotal}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={12} align="right">
                <LinkContainer to="/checkout">
                  <Button variant="outline-success">Checkout</Button>
                </LinkContainer>
                <Button
                  variant="outline-success"
                  onClick={() => {
                    console.log("Sub Total", subTotal);
                    console.log("Tax", tax);
                    console.log("Order Total", orderTotal);
                  }}
                >
                  Place Order
                </Button>
              </Col>
            </Row>
          </Col>
        </Row> */}
      </div>
    );
  }
}

export { subTotal, tax, orderTotal, shippingCharge, upadateQuantityFlag };
