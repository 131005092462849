import React, { Component } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { subTotal, tax, orderTotal, shippingCharge } from "./Table";

import {
  razorpay_order,
  orderId,
  selfpickup,
  coupenApplied,
  OrderTotal,
  coupenObj,
} from "./Summary";
import { uploadLength } from "./Uppy";
import { Redirect } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import "./checkout.css";
// import Razorpay from "razorpay";
import axios from "axios";

var defaultState = {
  name: null,
  email: null,
  mobile: null,
  company: null,
  billing_address: null,
  shipping_address: null,
  gst: null,
  city: null,
  pin: null,
  state: null,
  s_name: null,
  s_email: null,
  s_mobile: null,
  s_company: null,
  s_billing_address: null,
  s_shipping_address: null,
  s_gst: null,
  s_city: null,
  s_pin: null,
  s_state: null,
  s_orderId: orderId,
  s_subTotal: subTotal,
  s_tax: tax,
  s_orderTotal: orderTotal,
  s_uploadLength: uploadLength,
  s_handledBy: "Kailash",
  s_created_at: "",
  s_print_status: "Awaiting Confirmation",
  s_shipping_status: "Preparing for Shipping",
  s_tracking_number: "none",
  payment_id: null,
  isChecked: false,
  nameError: "",
  emailError: "",
  mobileError: "",
  billingAddressError: "",
  cityError: "",
  pinError: "",
  stateError: "",
  s_nameError: "",
  s_emailError: "",
  s_mobileError: "",
  s_shippingAddressError: "",
  s_cityError: "",
  s_pinError: "",
  s_stateError: "",
};

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.orderId = this.props.orderId;
    this.goBack = this.goBack.bind(this);
    console.log("orderId from checkout:", orderId);
    this.state = {
      orders: [],
      payment_status: false,
      name: null,
      email: null,
      mobile: null,
      company: null,
      billing_address: null,
      shipping_address: null,
      gst: null,
      city: null,
      pin: null,
      state: null,
      s_name: null,
      s_email: null,
      s_mobile: null,
      s_company: null,
      s_billing_address: null,
      s_shipping_address: null,
      s_gst: null,
      s_city: null,
      s_pin: null,
      s_state: null,
      s_orderId: orderId,
      s_shippingCharge: shippingCharge,
      s_subTotal: subTotal,
      s_tax: tax,
      s_orderTotal: orderTotal,
      s_uploadLength: uploadLength,
      s_handledBy: "Kailash",
      s_created_at: "",
      s_print_status: "Awaiting Confirmation",
      s_shipping_status: "Preparing for Shipping",
      s_tracking_number: "To be updated",
      s_courier_services: "To be updated",
      payment_id: null,
      isChecked: false,
      email_isChecked: true,
      email_update: false,
      nameError: "",
      emailError: "",
      mobileError: "",
      billingAddressError: "",
      cityError: "",
      pinError: "",
      stateError: "",
      s_nameError: "",
      s_emailError: "",
      s_mobileError: "",
      s_shippingAddressError: "",
      s_cityError: "",
      s_pinError: "",
      s_stateError: "",
    };
  }

  goBack() {
    this.props.history.goBack();
  }

  validate = () => {
    let emailError = "";
    let nameError = "";
    let mobileError = "";
    let billingAddressError = "";
    let cityError = "";
    let pinError = "";
    let stateError = "";
    let s_emailError = "";
    let s_nameError = "";
    let s_mobileError = "";
    let s_shippingAddressError = "";
    let s_cityError = "";
    let s_pinError = "";
    let s_stateError = "";

    if (!this.state.name) {
      nameError = "Name should not be blank";
    }
    if (!this.state.mobile) {
      mobileError = "Mobile number should not be blank";
    }
    if (!this.state.billing_address) {
      billingAddressError = "Billind Address should not be blank";
    }
    if (!this.state.city) {
      cityError = "City name should not be blank";
    }
    if (!this.state.pin) {
      pinError = "Pin number should not be blank";
    }
    if (!this.state.state) {
      stateError = " State name should not be blank";
    }
    if (this.state.email) {
      if (!this.state.email.includes("@")) {
        emailError = "Invalid Email";
      }
    } else {
      emailError = "email should not be blank";
    }

    if (!this.state.s_name) {
      s_nameError = "Name should not be blank";
    }
    if (!this.state.s_mobile) {
      s_mobileError = "Mobile number should not be blank";
    }
    if (!this.state.s_shipping_address) {
      s_shippingAddressError = "Shipping Address should not be blank";
    }
    if (!this.state.s_city) {
      s_cityError = "City name should not be blank";
    }
    if (!this.state.s_pin) {
      s_pinError = "Pin number should not be blank";
    }
    if (!this.state.s_state) {
      s_stateError = " State name should not be blank";
    }
    if (this.state.s_email) {
      if (!this.state.s_email.includes("@")) {
        s_emailError = "Invalid Email";
      }
    } else {
      s_emailError = "Email should not be blank";
    }

    if (
      nameError ||
      emailError ||
      mobileError ||
      billingAddressError ||
      cityError ||
      pinError ||
      stateError
    ) {
      this.setState({
        nameError,
        emailError,
        mobileError,
        billingAddressError,
        cityError,
        pinError,
        stateError,
      });
      return false;
    }
    if (
      s_nameError ||
      s_emailError ||
      s_mobileError ||
      s_shippingAddressError ||
      s_cityError ||
      s_pinError ||
      s_stateError
    ) {
      this.setState({
        s_nameError,
        s_emailError,
        s_mobileError,
        s_shippingAddressError,
        s_cityError,
        s_pinError,
        s_stateError,
      });
      return false;
    }

    return true;
  };

  inputChangerHandler = (event) => {
    event.preventDefault();

    console.log(event.target.value);

    this.setState({
      [event.target.name]: event.target.value,
    });

    if (event.target.name == "name") {
      this.setState({
        nameError: "",
      });
    }
    if (event.target.name == "email") {
      this.setState({
        emailError: "",
      });
    }
    if (event.target.name == "mobile") {
      this.setState({
        mobileError: "",
      });
    }
    if (event.target.name == "billing_address") {
      this.setState({
        billingAddressError: "",
      });
    }
    if (event.target.name == "city") {
      this.setState({
        cityError: "",
      });
    }
    if (event.target.name == "pin") {
      this.setState({
        pinError: "",
      });
    }
    if (event.target.name == "state") {
      this.setState({
        stateError: "",
      });
    }

    if (event.target.name == "s_name") {
      this.setState({
        s_nameError: "",
      });
    }
    if (event.target.name == "s_email") {
      this.setState({
        s_emailError: "",
      });
    }
    if (event.target.name == "s_mobile") {
      this.setState({
        s_mobileError: "",
      });
    }
    if (event.target.name == "s_shipping_address") {
      this.setState({
        s_shippingAddressError: "",
      });
    }
    if (event.target.name == "s_city") {
      this.setState({
        s_cityError: "",
      });
    }
    if (event.target.name == "s_pin") {
      this.setState({
        s_pinError: "",
      });
    }
    if (event.target.name == "s_state") {
      this.setState({
        s_stateError: "",
      });
    }
  };

  copyValueHandler = (event) => {
    this.setState({
      s_nameError: "",
      s_emailError: "",
      s_mobileError: "",
      s_shippingAddressError: "",
      s_cityError: "",
      s_pinError: "",
      s_stateError: "",
    });

    this.state.isChecked = !this.state.isChecked;
    if (this.state.isChecked == true) {
      this.setState({
        s_name: this.state.name,
        s_email: this.state.email,
        s_mobile: this.state.mobile,
        s_company: this.state.company,
        s_shipping_address: this.state.billing_address,
        s_gst: this.state.gst,
        s_city: this.state.city,
        s_pin: this.state.pin,
        s_state: this.state.state,
      });
      console.log("s_name:copy=", this.state.s_name);
    }
    if (this.state.isChecked == false) {
      this.setState({
        s_name: "",
        s_email: "",
        s_mobile: "",
        s_company: "",
        s_billing_address: "",
        s_shipping_address: "",
        s_gst: "",
        s_city: "",
        s_pin: "",
        s_state: "",
      });
      console.log("s_name:null=", this.state.s_name);
    }
  };

  emailUpadteHandler = () => {
    this.state.email_isChecked = !this.state.email_isChecked;
    if (this.state.email_isChecked) {
      axios
        .post(`https://print.3ding.in/server/orders/email_update/${orderId}`, {
          email_update: true,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(`https://print.3ding.in/server/orders/email_update/${orderId}`, {
          email_update: false,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  manualPay = (e) => {
    e.preventDefault();
    axios
      .post("https://print.3ding.in/server/orders", this.state)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post("https://print.3ding.in/server/user", this.state)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .post(`https://print.3ding.in/server/orders/manualUpdate/${orderId}`, {
        payment: true,
      })
      .then((res) => {
        var url = `/status/${orderId}`;
        alert("Payment successfull");
        this.props.history.push(url);
        // return <Redirect push to={url} />;
        // browserHistory.push('/status')
      })
      .catch(function (error) {
        console.log(error);
      });

    if (coupenObj && !coupenApplied) {
      axios
        .post(
          `https://print.3ding.in/server/orders/updateCoupen/${orderId}`,
          coupenObj
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  razorpayHandler = (e) => {
    console.log(this.state.s_orderTotal);
    //  let total = this.amount.toString();
    e.preventDefault();

    const isValid = this.validate();

    if (isValid) {
      this.setState({ defaultState });
      let options = {
        key: "rzp_live_ADtzP1cgVI1iDZ",
        // "key": "rzp_test_4eYGOTY1LzJucH",
        // "amount": this.state.s_orderTotal * 100,
        name: this.state.name,
        description: "ordered Items",
        image: "https://www.3ding.in/img/3d.png",
        order_id: razorpay_order,
        handler: (response) => {
          console.log("response.razorpay_payment_id", response);
          axios
            .post("https://print.3ding.in/server/payment-confirmation", {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              orderId: orderId,
            })
            .then((response) => {
              console.log(response);
              if (response.data == "success") {
                this.setState({
                  payment_status: true,
                });
              }

              //          axios.get(`https://print.3ding.in/server/${orderId}`)
              //  .then( (response) => {

              //    console.log('orders',response.data);
              //    this.setState({
              //        orders:response.data
              //    })
              //  })
              //  .catch(function (error) {
              //    console.log(error);
              //  });
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        prefill: {
          name: this.state.name,
          email: this.state.email,
        },
        notes: {
          address: "Hello World",
        },
        theme: {
          color: "#ff0000",
        },
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
      console.log("order_id", this.state.s_orderId);

      axios
        .post("https://print.3ding.in/server/orders", this.state)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

      axios
        .post("https://print.3ding.in/server/user", this.state)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      if (coupenObj && !coupenApplied) {
        axios
          .post(
            `https://print.3ding.in/server/orders//updateCoupen/${orderId}`,
            coupenObj
          )
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    if (this.state.payment_status) {
      var url = `/status/${orderId}`;
      return <Redirect push to={url} />;
      // browserHistory.push('/status')

      alert("Payment successfull");
    }

    return (
      <Container>
        {/* <button id="rzp-button1" >Pay</button> */}

        <Form onSubmit={this.submitHandler}>
          <br />
          <br />
          <Row>
            <Col sm={6} xs={12} align="center">
              <h3 style={{ paddingBottom: "13px" }}>Billing Details</h3>
              <br />

              <Row>
                <Col sm={4} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="name"
                    onChange={this.inputChangerHandler}
                    placeholder="Full Name"
                    required
                  />
                  {/* Name */}
                  <Row>
                    <Col sm={12} xs={12}>
                      <div style={{ fontSize: "12px", color: "red" }}>
                        {this.state.nameError}
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col sm={5} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="email"
                    onChange={this.inputChangerHandler}
                    placeholder="Email ID"
                    required
                  />
                  <Row>
                    <Col sm={12} xs={12}>
                      <div style={{ fontSize: "12px", color: "red" }}>
                        {this.state.emailError}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={3} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="mobile"
                    onChange={this.inputChangerHandler}
                    placeholder="Mobile #"
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.mobileError}
                    </div>
                  </Col>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={8} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Company Name (Optional)"
                    name="company"
                    onChange={this.inputChangerHandler}
                    required
                  />
                </Col>
                <Col sm={4} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="gst"
                    onChange={this.inputChangerHandler}
                    placeholder="GST # (Optional)"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={12} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows="3"
                    placeholder="Billing Address"
                    name="billing_address"
                    onChange={this.inputChangerHandler}
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.billingAddressError}
                    </div>
                  </Col>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={4} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="city"
                    onChange={this.inputChangerHandler}
                    placeholder="City"
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.cityError}
                    </div>
                  </Col>
                  {/* Name */}
                </Col>
                <Col sm={3} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="pin"
                    onChange={this.inputChangerHandler}
                    placeholder="PIN"
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.pinError}
                    </div>
                  </Col>
                </Col>
                <Col sm={5} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="state"
                    onChange={this.inputChangerHandler}
                    placeholder="State"
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.stateError}
                    </div>
                  </Col>
                </Col>
              </Row>
            </Col>
            {/*  */}
            {/*  */}
            {/* Shipping Details */}
            {/*  */}
            {/*  */}

            <Col sm={6} xs={12} align="center">
              <h3>Shipping Details</h3>

              <Row style={{ paddingBottom: "10px" }}>
                <Col sm={12} xs={12} align="center">
                  <div>
                    <input
                      type="checkbox"
                      defaultChecked={this.state.isChecked}
                      onClick={this.copyValueHandler}
                    />{" "}
                    Same as billing details
                  </div>
                </Col>
                <br />
              </Row>

              <Row>
                <Col sm={4} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="s_name"
                    value={this.state.s_name}
                    onChange={this.inputChangerHandler}
                    placeholder="Full Name"
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.s_nameError}
                    </div>
                  </Col>
                  {/* Name */}
                </Col>

                <Col sm={5} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="s_email"
                    value={this.state.s_email}
                    onChange={this.inputChangerHandler}
                    placeholder="Email ID"
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.s_emailError}
                    </div>
                  </Col>
                </Col>
                <Col sm={3} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="s_mobile"
                    value={this.state.s_mobile}
                    onChange={this.inputChangerHandler}
                    placeholder="Mobile #"
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.s_mobileError}
                    </div>
                  </Col>
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={12} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Company Name (Optional)"
                    name="s_company"
                    value={this.state.s_company}
                    onChange={this.inputChangerHandler}
                    required
                  />
                </Col>
                {/* <Col sm={4} xs={12} align="center">
                  <Form.Control size="sm" type="text" name="s_gst" value={this.state.s_gst} placeholder="GST #" />
                </Col> */}
              </Row>
              <br />
              <Row>
                <Col sm={12} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows="3"
                    placeholder="Shipping Address"
                    name="s_shipping_address"
                    value={this.state.s_shipping_address}
                    onChange={this.inputChangerHandler}
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.s_shippingAddressError}
                    </div>
                  </Col>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={4} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="s_city"
                    value={this.state.s_city}
                    onChange={this.inputChangerHandler}
                    placeholder="City"
                    required
                  />
                  {/* Name */}
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.s_cityError}
                    </div>
                  </Col>
                </Col>
                <Col sm={3} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="s_pin"
                    value={this.state.s_pin}
                    onChange={this.inputChangerHandler}
                    placeholder="PIN"
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.s_pinError}
                    </div>
                  </Col>
                </Col>
                <Col sm={5} xs={12} align="center">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="s_state"
                    value={this.state.s_state}
                    onChange={this.inputChangerHandler}
                    placeholder="State"
                    required
                  />
                  <Col sm={12} xs={12}>
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {this.state.s_stateError}
                    </div>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <h5 align="center">Order Total: {OrderTotal}</h5>
          <Row>
            <Col sm={4} align="center"></Col>
            <Col sm={4} align="center">
              <div>
                <input
                  type="checkbox"
                  defaultChecked={this.state.email_isChecked}
                  onClick={this.emailUpadteHandler}
                />{" "}
                Receive updates about this order via email
              </div>
            </Col>
            <Col sm={4} align="center"></Col>
          </Row>

          <Row>
            <Col sm={12} align="center" style={{ paddingTop: "20px" }}>
              {/* <LinkContainer to="/home">
              <Button
                // bsStyle={border-radius: 0;background: red;}
                variant="success"
                onClick={() => {
                  console.log("Payment", 100);
                }}
              >
                Proceed to Payment
              </Button>
            </LinkContainer> */}

              {/* <Link to='/upload'>
            <Button variant="light" type="button" > Back</Button>
      </Link> */}

              <Button variant="light" type="button" onClick={this.goBack}>
                {" "}
                Back
              </Button>

              <span> &nbsp;&nbsp;&nbsp;</span>
              {!coupenApplied && OrderTotal === 0 ? (
                <Button
                  variant="success"
                  type="submit"
                  onClick={this.manualPay}
                >
                  {" "}
                  Proceed to Pay{" "}
                </Button>
              ) : (
                <Button
                  variant="success"
                  type="submit"
                  onClick={this.razorpayHandler}
                >
                  {" "}
                  Proceed to Pay{" "}
                </Button>
              )}

              {/* <button type="submit" onClick={this.razorpayHandler}>Proceed to payment</button> */}
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default Checkout;

// import React from 'react';
// import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
// import { Panel, PanelGroup, Accordion } from 'react-bootstrap';

// const panels = {
//   backgroundImage: 'none',
//   color: 'white',
//   backgroundColor: 'red',
//   borderColor: '#ddd',
//   fontSize: '18px'
// }
// const sb = {
//   paddingLeft: '20px',
// }
// const btnDanger = {
//   backgroundColor: 'Red',
//   borderColor: 'Red',
//   borderRadius: '0',
// }

// const fs = {
//   width: '137px',

// }
// const fs2 = {
//   width: '137px',
//   marginLeft: '2px',
// }

// const fs3 = {
//   width: '134px',
//   marginLeft: '2px',
// }
// const fullfs = {
//   paddingBottom: '7px'
// }

// export default class Contact extends React.Component {
//   handleChange() {
//     var name = document.getElementById("name").value;
//     var email = document.getElementById("email").value;
//     var phone = document.getElementById("phone").value;
//     var address = document.getElementById("address1").value;
//     var city = document.getElementById("city").value;
//     var state = document.getElementById("state").value;
//     var pincode = document.getElementById("pincode").value;
//     document.getElementById("email2").value = email;
//     document.getElementById("phone2").value = phone;
//     document.getElementById("address2").value = address;
//     document.getElementById("city2").value = city;
//     document.getElementById("state2").value = state;
//     document.getElementById("pincode2").value = pincode;

//   }
//   proceed() {
//     var Name = document.getElementById('name').value;
//     var Email = document.getElementById('email').value;
//     var Phone = document.getElementById("phone").value;
//     var Address = document.getElementById("address1").value;
//     var City = document.getElementById("city").value;
//     var State = document.getElementById("state").value;
//     var Pincode = document.getElementById("pincode").value;
//     fetch('http://localhost:3000/addRequests', {
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         name: Name,
//         email: Email,
//         phone: Phone,
//         address: Address,
//         city: City,
//         state: State,
//         pincode: Pincode

//       }),

//     })
//   }
//   render() {

//     return (
//       <div id="contactform">
//         <Container fluid>
//           <br></br>
//           <br></br>
//           <Row>
//             <Col sm="2"></Col>
//             <Col sm="4">
//               <Panel>
//                 <Panel.Heading style={panels}>Billing Details</Panel.Heading>
//                 <Panel.Body>
//                   <Form inline style={fullfs}>
//                     <div>

//                       <FormGroup>

//                         <Input type="name" name="name" id="name" placeholder="Name" style={fs} />

//                       </FormGroup>
//                       <FormGroup>

//                         <Input type="name" name="email" id="email" placeholder="Email" style={fs2} />
//                       </FormGroup>
//                       <FormGroup>

//                         <Input type="number" name="phone" id="phone" placeholder="Phone Number" style={fs3} />
//                       </FormGroup>
//                     </div>
//                   </Form>
//                   <Form>
//                     <FormGroup>

//                       <Input type="name" name="companyName" id="cn" placeholder="Company Name" />
//                     </FormGroup>
//                     <FormGroup>

//                       <Input type="name" name="gst" id="gst" placeholder="GST" />
//                     </FormGroup>
//                     <FormGroup>

//                       <Input type="textarea" name="text" id="address1" placeholder="Address" />
//                     </FormGroup>
//                     <FormGroup>

//                       <Input type="name" name="city" id="city" placeholder="City" />
//                     </FormGroup>
//                     <FormGroup>
//                       <Input type="select" name="selectMulti" id="state">

//                         <option>State</option>
//                         <option>Andhra Pradesh</option>
//                         <option>Arunachal Pradesh</option>
//                         <option>Assam</option>
//                         <option>Bihar</option>
//                         <option>Chhattisgarh</option>
//                       </Input>
//                     </FormGroup>

//                     <FormGroup>
//                       <Input type="name" name="city" id="pincode" placeholder="Pincode" />
//                     </FormGroup>
//                   </Form>
//                 </Panel.Body>
//               </Panel>
//             </Col>
//             <Col sm="4">
//               <Panel>
//                 <Panel.Heading style={panels}>Shipping Details</Panel.Heading>
//                 <Panel.Body>
//                   <Form>
//                     <FormGroup check>

//                       <Input type="checkbox" onClick={this.handleChange} />

//                       <p style={sb}>Shipping same as Billing</p>

//                     </FormGroup>

//                     <FormGroup>

//                       <Input type="name" name="email" id="email2" placeholder="Email" />
//                     </FormGroup>
//                     <FormGroup>

//                       <Input type="name" name="password" id="phone2" placeholder="Phone Number" />
//                     </FormGroup>
//                     <FormGroup>

//                       <Input type="textarea" name="text" id="address2" />
//                     </FormGroup>
//                     <FormGroup>

//                       <Input type="name" name="city2" id="city2" placeholder="City" />
//                     </FormGroup>
//                     <FormGroup>
//                       <Input type="select" name="selectMulti" id="state2">

//                         <option>State</option>
//                         <option>Andhra Pradesh</option>
//                         <option>Arunachal Pradesh</option>
//                         <option>Assam</option>
//                         <option>Bihar</option>
//                         <option>Chhattisgarh</option>

//                       </Input>
//                     </FormGroup>
//                     <FormGroup>

//                       <Input type="name" name="pincode2" id="pincode2" placeholder="Pincode" />
//                     </FormGroup>
//                     <br></br>

//                   </Form>
//                 </Panel.Body>
//               </Panel>
//             </Col>
//             <Col sm="2"></Col>
//           </Row>

//         </Container>
//         <Container>
//           <Row>
//             <Col sm="2"></Col>
//             <Col sm="8" align="center">
//               <Button color="danger" style={btnDanger} onClick={this.proceed}>Proceed to Pay</Button>
//             </Col>
//             <Col sm="2"></Col>

//           </Row>

//         </Container>
//       </div>
//     );
//   }
// }
// ReactDOM.render(<Contact />, document.getElementById('host'));
