import React, { Component } from "react";

import { Button, Navbar, Nav, NavItem } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { Router, Route, Link } from 'react-router';
import { LinkContainer } from "react-router-bootstrap";

export default class NavbarComponent extends Component {







  render() {
    return (
       <div>
           <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        fixed="top"
        sticky="top"
        style={{height: "50px"}}
      >
        <LinkContainer to="/">
          <Navbar.Brand > <img style={{marginTop: "9px",width:"90%"}}   src="https://www.3ding.in/img/3d.png"/></Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Item>
              {/* <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer> */}
            </Nav.Item>
            <Nav.Item>
              <LinkContainer to="/upload">
                <Nav.Link>Upload</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
            {/* <Navbar.Text>
          <Navbar.Link href="https://www.3ding.in/contacts" target="_blank">Contact</Navbar.Link>
        </Navbar.Text> */}
            </Nav.Item>

            
            {/* <Nav.Item>
            <LinkContainer to="/status">
                <Nav.Link>Status</Nav.Link>
              </LinkContainer>
            </Nav.Item> */}

            <Nav.Item>
            <LinkContainer to="/contact">
                <Nav.Link>Contact</Nav.Link>
              </LinkContainer>

            </Nav.Item>
            <Route path='/contact' component={() => { 
                window.location.href = 'https://www.3ding.in/contacts'; 
               return null;
            }}/>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
            

       </div>


      

      // <Navbar default collapseOnSelect>
      //   <Navbar.Header>
      //     <Navbar.Brand>
      //       <Link to="/">3Ding</Link>
      //     </Navbar.Brand>
      //     <Navbar.Toggle />
      //   </Navbar.Header>
      //   <Navbar.Collapse>
      //     <Nav pullRight>
      //       <NavItem eventKey={1} componentClass={Link} to="/">
      //         Home
      //       </NavItem>
      //       <NavItem eventKey={2} componentClass={Link} to="/upload ">
      //         Upload
      //       </NavItem>
      //       <NavItem eventKey={3} componentClass={Link} to="/checkout ">
      //         Checkout
      //       </NavItem>
      //     </Nav>
      //   </Navbar.Collapse>
      // </Navbar>
    );
  }
}
