import React, { Component } from "react";
import UppyComponent from "./Uppy";
import TableComponent from "./Table";
 
import SummaryComponent from "./Summary";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Redirect } from 'react-router';
import { orderId } from "../App.js";
import { Button,Container, Row, Col } from "react-bootstrap";
// import App from "../App";

class EditUpload extends Component {
  constructor(props) {
    super(props);
    // this.orderId = `orderid_${Date.now()}`;
    // this.orderId = orderId;
    // this.orderId = 1567484635508;

    if(this.props.match.params.order_id){
        this.orderId = parseInt(this.props.match.params.order_id, 10);
    //   this.orderId = this.props.match.params.order_id
      this.external_access=true
      }
    else{
      this.orderId = orderId;
      this.external_access=false
    }
    this.state = {};
    
    console.log("OrderId:", this.props.match.params.order_id);
    // console.log("This:", this);
  }
  componentDidMount() {
    // console.log("App Started");
  }
  render() {
    return (
      <Container>
        <br />
     
        
        {/* <Col sm={9} xs={12}>
          <UppyComponent orderId={this.orderId} external_access={this.external_access} />
        </Col> */}
        {/* <Col sm={8} xs={12}></Col> */}

      <Row>
        <Col sm={12} xs={12}>
          <SummaryComponent orderId={this.orderId} external_access={this.external_access} />
        </Col>
      </Row>

      <Row>
        <TableComponent orderId={this.orderId} external_access={this.external_access}/>
      </Row>
        
        <br/><br/>
       
        
        

        {/* <SummaryComponent /> */}
      </Container>
    );
  }
}

export default EditUpload;
