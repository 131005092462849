import React, { Component } from "react";
import { Button,Col, Row, Table,Container,Modal} from 'react-bootstrap';
import { orderId } from "../App.js";
import {Link} from 'react-router-dom';
import "./status.css";
import axios from 'axios';
import { Redirect } from 'react-router';


class Status extends Component {
  constructor(props) {
    super(props);
    // this.orderId = `orderid_${Date.now()}`;
    this.orderId = orderId;

    this.state = {
        orders:[],
        name:"",
        email:"",
        razorpay_order:"",
        modal:true,
        active1: false,
        active2: false,
        active3: false,
        active4: false,
        active5: false,
       
        feedback: {
          content:"",
          rating:0,
          feedback:false
          },
         

        order_placed:false,
        order_processed:false,
        order_shipped:false,
        order_completed:false,
        feedback_flag:false,

        display_message : "",
        tracking_number:"",
        courier_service:"",
        display_button:false
    };

    console.log("OrderId:", this.orderId);
    // console.log("This:", this);
  }
  componentDidMount() {

    const script = document.createElement("script");

    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
  
    document.body.appendChild(script);


    axios.get(`https://print.3ding.in/server/orders/${this.props.match.params.order_id}`)
    .then( (response) => {
      
      console.log('orders',response.data);
      this.setState({
          orders:response.data
      })
      {this.state.orders.map(order => {
         
        this.setState({
           name:order.name,
           email:order.email,
           razorpay_order:order.razorpay_data.id,
           feedback_flag: order.feedback
      })
      })}
      })
    .catch(function (error) {
      console.log(error);
    });

  }


  handleChangeFor = (propertyName) => (event) => {
    const { feedback } = this.state;
    const newFeedback = {
      ...feedback,
      [propertyName]: event.target.value
    };
    this.setState({ feedback: newFeedback });
  }
  
  // redirect = () =>{
  //   console.log(`/upload/${this.props.match.params.order_id}`)
  //   var url = ` http://localhost:3000/upload/${this.props.match.params.order_id}`;
    
  //   return <Redirect push to={url} />
  // }

  call1=()=>{
   
     if( this.state.display_button !== true){
        
     
return  <Row>
          <Col sm="3"></Col>
          <Col sm="6"><h5 style={{paddingTop: '9%',textAlign:'center'}}><Button variant="success" type="submit"  onClick={this.razorpayHandler}> Proceed to Pay </Button></h5> </Col>
          <Col sm="3"></Col>
        </Row>
    
    }
    
  
  }

  call2=()=>{
    if(this.state.order_shipped || this.state.order_completed){
      {this.state.orders.map(order => {
        
        this.state.tracking_number=order.tracking_number
        this.state.courier_service=order.courier_services
      
      })}
     
      return  <div style={{paddingTop:'4%',color:"red"}}>
                <h6 align="center"> Your Tracking Id: {this.state.tracking_number}</h6> 
                 <h6 align="center">Courier Service: {this.state.courier_service}</h6> 
              </div>
          
          }
  }

  razorpayHandler=(e)=>{
     e.preventDefault();
    console.log("name, email, id:", this.state.name, this.state.email, this.state.razorpay_order)
    let options = {
    
       "key": "rzp_live_ADtzP1cgVI1iDZ", 
      // "key": "rzp_test_4eYGOTY1LzJucH",
      // "amount": this.state.s_orderTotal * 100,
      "name": this.state.name,
      "description": "ordered Items",
      "image": "https://www.3ding.in/img/3d.png",
      "order_id": this.state.razorpay_order,
      "handler": (response) => {
        console.log("response.razorpay_payment_id",response);
        axios.post("https://print.3ding.in/server/payment-confirmation",  {
          razorpay_order_id : this.state.razorpay_order,
          razorpay_payment_id :response.razorpay_payment_id ,
          razorpay_signature: response.razorpay_signature,
          orderId:parseInt(this.props.match.params.order_id, 10)
      })
            .then( (response) => {
  
               console.log(response);
                 if(response.data == "success"){
                  window.location.reload();
                   this.setState({
                     payment_status:true
                   })
                 }
               
  })
                     .catch(function (error) {
                       console.log(error);
                      });
        
      },
      "prefill": {
        "name": this.state.name,
        "email": this.state.email
      },
      "notes": {
        "address": "Hello World"
      },
      "theme": {
        "color": "#ff0000"
      }
    }
    let rzp = new window.Razorpay(options);
    rzp.open();


  }

  toggle= ()=> {
    let value = !this.state.modal
    
    this.setState({
       modal:value
    })
  }

  submitFeedback = () =>{
    this.state.feedback.feedback = true;
    axios.post(`https://print.3ding.in/server/orders/feedback/${this.props.match.params.order_id}`, this.state.feedback)
    .then((response)=> {
     console.log(response.data);
     let value = !this.state.modal
    
    this.setState({
       modal:value
    })
    })
    .catch(function (error) {
    console.log(error);
    });
  }


  toggleClass=(value)=> {

     
    if(value === 1){
      this.state.feedback.rating = 0
      const currentState = this.state.active1;
      if(currentState === true){
        this.setState({    active1: !currentState,
          active2: !currentState,
          active3: !currentState,
          active4: !currentState, 
          active5: !currentState });
      }else{
        this.state.feedback.rating = 1
        this.setState({ active1: !currentState });
      }
      
  }
  else if(value === 2){
      const currentState = this.state.active2;
      this.state.feedback.rating = 0
      if(currentState === true){
        this.setState({    active1: !currentState,
          active2: !currentState,
          active3: !currentState,
          active4: !currentState, 
          active5: !currentState });
      }else{
        this.state.feedback.rating = 2
        this.setState({
          active1: !currentState,
          active2: !currentState,
        });
      }
      
    }else if(value === 3){
      const currentState = this.state.active3;
      this.state.feedback.rating = 0
      if(currentState === true){
        this.setState({  active1: !currentState,
          active2: !currentState,
          active3: !currentState,
          active4: !currentState, 
          active5: !currentState });
      }else{
        this.state.feedback.rating = 3
        this.setState({
          active1: !currentState,
          active2: !currentState,
          active3: !currentState
           });
      }
   
    }else if(value === 4){
      const currentState = this.state.active4;
      this.state.feedback.rating = 0
      if(currentState === true){
        this.setState({  active1: !currentState,
          active2: !currentState,
          active3: !currentState,
          active4: !currentState, 
          active5: !currentState });
      }else{
        this.state.feedback.rating = 4
        this.setState({
          active1: !currentState,
          active2: !currentState,
          active3: !currentState,
          active4: !currentState });
      }
   
    }else if(value === 5){
      const currentState = this.state.active5;
      this.state.feedback.rating = 0
      if(currentState === false){
        this.state.feedback.rating = 5
      }
      this.setState({
        active1: !currentState,
        active2: !currentState,
        active3: !currentState,
        active4: !currentState, 
        active5: !currentState,
        });
    }

    
   
  }

  render() {
      console.log('this.state.feedback.content:',this.state.feedback.content)
      console.log('this.state.feedback.rating:',this.state.feedback.rating)
      console.log('this.state.feedback.feedback_flag:',this.state.feedback_flag)
      console.log('this.state.modal',this.state.modal)

      {this.state.orders.map(order => {
          this.state.order_placed = order.order_placed
          if(order.order_placed === true)
          {
            
              this.state.display_button=true
          
          }
        })}

        {this.state.orders.map(order => {
           if((this.state.order_placed===true)&&(order.print_status == 'Printing Complete')){
            this.state.order_processed = true
           }
        })}


        {this.state.orders.map(order => {
          if((this.state.order_placed===true)
          &&(order.print_status == "Printing Complete")
          &&(order.shipping_status == "Shipped" ))
          {
           this.state.order_shipped = true
          }
       })}

       {this.state.orders.map(order => {
        if((this.state.order_placed===true)
        &&(order.print_status == "Printing Complete")
        &&(order.order_shipped === true)
        &&(order.shipping_status == "Delivered" ))
        {
         this.state.order_completed = true
        }
     })}

       {this.state.orders.map(order => {
         if(order.order_placed && (order.print_status !== "Printing Complete")){
                  if((order.print_status === "Awaiting Confirmation")){
                     this.state.display_message = "A representative will manually verify your files for printability & process it for printing shortly."
                  }
                  else if((order.print_status === "File Error")){
                    this.state.display_message = "Looks like there are some errors with your files. One of our Engineers will contact you to assist further."
                  }
                  else{
                    this.state.display_message ="Your order is being processed. We’ll update you when it’s done."
                  }
         }
         else if(order.order_placed && (order.print_status === "Printing Complete")&& (order.shipping_status !== "Shipped")&& (order.shipping_status !== "Delivered")){
                 this.state.display_message = "Your order has been processed successfully. We’re preparing it to ship."
         }
         else if(order.order_placed && (order.print_status === "Printing Complete")&& (order.shipping_status === "Shipped")&& (order.order_shipped === true)){
                  this.state.display_message = " Your order has been shipped , Your order will be arrived soon."
                  // this.state.tracking_number = order.tracking_number
                  // this.state.courier_service = order.courier_services
         }
         else if(order.order_placed && (order.print_status === "Printing Complete")&& (order.shipping_status === "Delivered")&& (order.order_shipped  === true)){
                  this.state.display_message = "Your order has been successfully delivered, Kindly rate it & provide feedback if any. "
                  // this.state.tracking_number = order.tracking_number
                  // this.state.courier_service = order.courier_service
         }
         else{
                  this.state.display_message = "Welcome to 3Ding; Kindly place an order to proceed further."
         }



     })}

       


    return (
<div>
<h5  className="orderId">Your order id: {this.props.match.params.order_id}</h5>
 <div  className="status-width">
 {/* <div style={{width:"80%",marginLeft:"12%"}}> */}

   <ol className="progtrckr" data-progtrckr-steps="4" style={{marginTop: '8%'}} id="stepBar">
   <li  className={ this.state.order_placed ? 'progtrckr-done' : 'progtrckr-todo' }>Placed</li>
   {/* <li  className={ this.state.order_placed ? 'progtrckr-done' : 'progtrckr-todo' ? 'd-sm-none':'d-sm-none' }>placed</li> */}
    <li  className={  this.state.order_processed  ? 'progtrckr-done' :  this.state.order_placed  ? 'progtrckr-done1' : 'progtrckr-todo' }>Processing</li>
   <li  className={ (this.state.order_shipped || this.state.order_completed) ? 'progtrckr-done' : this.state.order_processed   ? 'progtrckr-done1' : 'progtrckr-todo' }>Shipping</li>
   <li  className={ this.state.order_completed ? 'progtrckr-done' : 'progtrckr-todo' }>Delivered</li>
   
</ol>
 </div>


      
       {/* <Button color="danger" onClick={this.toggle}>Modal Window</Button>
       <Modal show={this.state.modal} onHide={this.toggle}  >
       <Modal.Header closeButton> 
       <div>
       <h4>Customer Feedback</h4>
       </div>
       </Modal.Header>
       <Modal.Body>
             <div align="center">
              <span className="fa fa-star fa-lg" id={this.state.active1 ? 'feedbackStar': null} onClick={()=>this.toggleClass(1)} ></span>
              <span className="fa fa-star fa-lg" style={{marginLeft:"4px"}} id={this.state.active2 ? 'feedbackStar': null} onClick={()=>this.toggleClass(2)}></span>
              <span className="fa fa-star fa-lg" style={{marginLeft:"4px"}} id={this.state.active3 ? 'feedbackStar': null} onClick={()=>this.toggleClass(3)}></span>
              <span className="fa fa-star fa-lg" style={{marginLeft:"4px"}} id={this.state.active4 ? 'feedbackStar': null} onClick={()=>this.toggleClass(4)}></span>
              <span className="fa fa-star fa-lg" style={{marginLeft:"4px"}} id={this.state.active5 ? 'feedbackStar': null} onClick={()=>this.toggleClass(5)}></span>
             </div>
        <textarea value={this.state.feedback.content} style={{width:"100%",height:"150px",marginTop:"10px"}} onChange={this.handleChangeFor('content')}> </textarea> 
       </Modal.Body>
       <Modal.Footer>
       <Button color="primary" onClick={this.submitFeedback}>Submit</Button>{' '}
       <Button color="secondary" onClick={this.toggle}>Cancel</Button>
       </Modal.Footer>
       </Modal> */}

   <h5 align="center">{this.state.display_message}</h5> 
   {this.call1()}
  {
    this.state.order_completed ?  <div>
     {(this.state.modal && (this.state.feedback_flag === false || this.state.feedback_flag === undefined )) ? <div align="center" style={{paddingTop:"10px"}}>
     {/* {this.state.modal ? <div align="center" style={{paddingTop:"10px"}}> */}
    <span className="fa fa-star fa-lg" id={this.state.active1 ? 'feedbackStar': "StarColor"} onClick={()=>this.toggleClass(1)} ></span>
    <span className="fa fa-star fa-lg" style={{marginLeft:"4px"}} id={this.state.active2 ? 'feedbackStar': "StarColor"} onClick={()=>this.toggleClass(2)}></span>
    <span className="fa fa-star fa-lg" style={{marginLeft:"4px"}} id={this.state.active3 ? 'feedbackStar': "StarColor"} onClick={()=>this.toggleClass(3)}></span>
    <span className="fa fa-star fa-lg" style={{marginLeft:"4px"}} id={this.state.active4 ? 'feedbackStar': "StarColor"} onClick={()=>this.toggleClass(4)}></span>
    <span className="fa fa-star fa-lg" style={{marginLeft:"4px"}} id={this.state.active5 ? 'feedbackStar': "StarColor"} onClick={()=>this.toggleClass(5)}></span>
    <div>
     <textarea value={this.state.feedback.content} placeholder="Feedback" id="feedbackBox" onChange={this.handleChangeFor('content')}> </textarea>
   </div>
<div><Button color="primary" style={{marginTop:"10px"}} onClick={this.submitFeedback}>Submit</Button></div>
  </div>:null}
</div> : this.call2() 
    
  }
     
  
   
   
 

  
</div>
    );
  }
}

export default Status;
