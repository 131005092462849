import React, { Component } from "react";
import { Container } from "react-bootstrap";

class Download extends Component {
  render() {
    return (
      <Container>
        <div>Download Page</div>
      </Container>
    );
  }
}

export default Download;
